<template>
  <section>
    <ul class="container">
      <li class="card table-list">
        <h3>{{ $t("blocks") }}</h3>
        <block-table :list="blocks" :isLoading="blocksLoading"/>
        <!-- 翻页 -->
        <pagination-view
          :limit="limit"
          :total="total"
          :page="page"
          @emitPage="getBlocks"
        ></pagination-view>
      </li>
    </ul>
  </section>
</template>

<script>
import PaginationView from "base/pagination";
import BlockTable from 'base/block-table';
export default {
  data() {
    return {
      blocks: null,
      blocksLoading: true,
      total: 0,
      limit: 20,
      page: 1,
    };
  },
  created() {
    this.getBlocks(1);
  },
  methods: {
    getBlocks(page) {
      this.page = page;
      this.blocksLoading = true;

      this.axios
        .get(this.domain + "v1/block?page[size]="+this.limit+"&page[number]=" + page)
        .then(res => {
          this.blocks = res.data.data;
          this.total = res.data.total;
          this.blocksLoading = false;
        })
        .catch(() => {
          this.blocks = null;
          this.blocksLoading = false;
          console.log("Get blocks failed");
        });
    },
  },
  components: {
    PaginationView,
    BlockTable,
  },
};
</script>

<style scoped>
</style>
